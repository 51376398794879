<template>
  <div>
    <ul class="status_ul flex" :style="{ 'width': width + 'px' }">
      <li :class="order_status === item.id ? 'active' : ''" v-for="(item, index) in statusList" :key="index"
          @click="status_tab(item)">
        {{ item.title }}
        <el-badge :value="item.num" class="item" v-if="item.id !== '' && item.id != 2 && item.num" :max="99">

        </el-badge>
        <!-- <span class="status_num" v-if="item.id !== '' && item.id != 2 && item.num">{{
        item.num
    }}</span> -->

      </li>
    </ul>
  </div>
</template>

<script>
import {getOrderNum} from '@/util/orderNum'

export default {
  mounted() {
    this.statusList = JSON.parse(JSON.stringify(this.$props.status_list))
    this.getNum()
  },
  props: {
    width: {
      type: String,
      default: '520'
    },
    status_list: {
      type: [Array],
      default: ''
    },
    order_status: {
      type: [Number, String],
      default: '',
    },
    type: String,
    category_id: {
      type: [Number],
    }
  },
  data() {
    return {
      statusList: [],
    }
  },
  methods: {
    getNum() {
      if (this.category_id) {
        getOrderNum(this.statusList, this.type, this.category_id, this.order_status)

      } else {
        getOrderNum(this.statusList, this.type)
      }
    },
    status_tab(item) {
      this.$emit('status_tab', item.id)
      this.getNum()

    },
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/order_status'
</style>
import { curlGet } from '../api/request'
import Vue from '../main'
// 获取订单-稿件未处理数量
export function getOrderNum(array, type, category_id, class_name, status) {
    let url = ''
    if (class_name == 'placard') {
        url = '/api/users/guanggao_order/num'
    } else {
        url = '/api/users/order/num'
    }
    curlGet(url, {
        category_id: category_id
    }).then(res => {
        if (res.data.code) {
            res.data.data[type].forEach((item) => {
                const target = array.find(el => el.id === item.order_status || el.id === item.limit_time);
                if (target) {
                    Vue.$set(target, 'num', item.num);
                }
            });
        }
    })
}
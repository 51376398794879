<template>
    <div style="margin-right: 20px;">
        <el-date-picker value-format="timestamp" @change="changeTime" v-model="time" type="daterange"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
    </div>
</template>

<script>
export default {
    data() {
        return {
            time: [],
        }
    },
    methods: {
        changeTime() {
            this.$emit('get_time', this.time)
        }
    },
}
</script>

<style lang=scss scoped>
::v-deep .el-range-editor.el-input__inner {
    width: 299px;
    font-size: 14px;
}

::v-deep .el-input__inner {
    line-height: 30px;
    height: 30px;
}

::v-deep .el-range-separator {
    height: 30px;
}

::v-deep .el-date-editor .el-range__icon {
    line-height: 24px;
}

::v-deep .el-date-editor .el-range__close-icon {
    line-height: 24px;
}
</style>